<template>
  <div class="bgimg" @click="routerToNewUrl">
    <div class="info">
      <div class="top">
        <p class="one">{{ baseInfoZHUp }}</p>
        <p class="two">{{ baseInfoZHDown }}</p>
      </div>
      <div class="bottom">{{ baseInfo }}</div>
      <div class="countdown">
        {{ countdownShow }}
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'jquery';
import router from '../router';

export default {
  name: 'under_contructions',
  components: {},
  data() {
    return {
      baseInfo:
        'The domain name bigbigholiday.com will soon be replaced by t360.com.hk. This transitional page will be shut down on April 18, 2025. Please update your bookmarks. Thank you!',
      baseInfoZHUp: '域名bigbigholiday.com将很快被t360.com.hk取代。',
      baseInfoZHDown:
        '此过渡页面将于2025年4月18日关闭，请更新您的收藏夹。谢谢！',
      countdown: ' Redirecting in {num} seconds...',
      countdownNum: 15,
      timer: null,
    };
  },
  methods: {
    getRedirect(){
      const host = window.location.origin;
      if(host.includes('bigbigholiday')){
        this.$router.push('/transition');
      }
    },
    startCountdown() {
      this.timer = setInterval(() => {
        if (this.countdownNum > 0) {
          this.countdownNum--;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.timer);
      this.timer = null;
      window.location.href = 'https://t360.com.hk';
    },
    routerToNewUrl(){
      clearInterval(this.timer);
      this.timer = null;
      window.location.href = 'https://t360.com.hk';
    }
  },
  mounted() {
    this.startCountdown();
  },

  computed: {
    countdownShow() {
      return this.countdown.replace('{num}', this.countdownNum);
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style type="text/css" >
.bgimg {
  background-image: url('../assets/img/transition-bg.png');
  padding: 0;
  background-size: cover; /* 使背景图覆盖整个页面 */
  background-position: center; /* 将背景图居中 */
  background-repeat: no-repeat; /* 防止背景图重复 */
  height: 100vh;
  width: 80vw;
  margin: 0 auto;
  /* width: 100vw; */
  /* margin: 0;
  height: 100vh;
  /* width: 100%;
  height: 100%; */
  background-size: 100% 100%;
  /* position: absolute; */
  color: white;
  font-family: 'Courier New', Courier, monospace;
  font-size: 25px;
  overflow: hidden;
  cursor:pointer
}
.bgimg .info {
  width: 80vw;
  height: 40vh;
  margin: 38vh auto;
  color: #3b3e66;
  font-weight: 700;
}

.bgimg .info .top {
  text-align: center;
}
.bgimg .info .top .one {
  font-size: 35px;
}
.bgimg .info .bottom {
  width: 73vw;
  margin: 0 auto;
}
.countdown{
  width: 65vw;
    text-align: center;
    line-height: 24vh;
}

</style>
